import React, {useState} from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import PopIn from "../layout/PopIn";
import Spacer from "../layout/Spacer";

const Comp = styled(Text)`
  border: 2px solid ${({theme}) => theme.colors.text};
  width: 100%;
  max-width: calc(${({theme}) => theme.breakPoints.s} * 0.7);
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  border-radius: 1rem;
  cursor: pointer;
  text-align: center;

  :hover {
    background-color: ${({theme}) => theme.colors.text};
    color: ${({theme}) => theme.colors.background};
  }
`

const LearnMore = (props) => {
    const {data} = props
    const [open, setOpen] = useState(false);

    return (
        <>
            <Comp s="standard" onClick={() => setOpen(true)}>
                {data.title}
            </Comp>
            <Spacer s="large"/>

            <PopIn text={data.Text} open={open} setOpen={setOpen}/>
        </>
    );
};

export default LearnMore;