import React from "react";
import { Link as GatsbyLink } from "gatsby";
// import { useLanguageContext } from "../../contexts/LanguageContext";

const Link = ({ to, children, greyBg, ...rest }) => {
	// const language = useLanguageContext();
	const linkRegex = new RegExp("^(http|https):\\/\\/", "i");
	const mailRegex = new RegExp("^mailto:", "i");

	if (to) {
		if (linkRegex.test(to) || mailRegex.test(to)) { // if it is an external link
			return <a {...rest} href={to} target="_blank"
					  rel="noopener noreferrer">
				{children}
			</a>;
		} else { // if it is an internal (relative) link
			return <GatsbyLink
				{...rest}
				// to={`${language.lang === "fr" ? to : `/${language.lang}${to}`}`}
				to={to}
			>
				{children}
			</GatsbyLink>;
		}
	} else { // if there are no links (react button)
		return <div {...rest}>{children}</div>;
	}

	// return <GatsbyLink {...rest} to={`${language.lang === "fr" ? to : `/${language.lang}${to}`}`} />;
};

export default Link;