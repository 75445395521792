import React from 'react';
import styled from "styled-components";
import BlockContent from "@sanity/block-content-to-react";
import Text from "../layout/Text";
import Link from "../localization/Link";
import Spacer from "../layout/Spacer";
import Separator from "../layout/Separator";
import EditorialImage from "./EditorialImage";
import ImageText from "./ImageText";
import LearnMore from "./LearnMore";
import VideoPlayer from "./VideoPlayer";
import slugify from "slugify";

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const Block = (props) => {
    const {alert, imageText} = props;

    let chapterCount = 0;
    const serializers = {
        container: props => (<BlockContainer article>{props.children}</BlockContainer>),
        types: {
            block: props => {
                if (props.node.style === "h1") {
                    chapterCount++
                }

                // console.log(props.node.style === "h1" ? chapterCount : null)
                return (
                    <>
                        <Text
                            as={props.node.style === "h1" ? "h1" : props.node.style === "h3" ? "h3" : "p"}
                            s={alert ? "subtitle" : props.node.style === "h1" ? "title" : props.node.style === "h3" ? "contentTitle" : "standard"}
                            // style={{ display: "block" }}
                            id={props.node.style === "h1" ? chapterCount + "_" + slugify(props.children[0], {
                                replacement: "-",
                                lower: true
                            }) : null}
                            style={{paddingTop: props.node.style === "h1" ? "5.5rem" : 0}}
                            a={props.node.style === "h1" ? "center" : null}
                        >
                            {props.children}
                        </Text>
                        {imageText ? null : alert ? null : props.node.style === "h1" ? <Spacer s="large"/> : props.node.style === "h3" ?
                            <Spacer s="small"/> : <Spacer s="large"/>}
                    </>
                );
            },
            editorialImage: props => (<EditorialImage data={props.node}/>),
            imageText: props => (<ImageText data={props.node}/>),
            learnMore: props => (<LearnMore data={props.node}/>),
            separator: props => (props.node.type === "full" ? <Separator full/> : <Separator/>),
            youtube: props => (<VideoPlayer data={props}/>),
        },
        marks: {
            h1: props => (
                <div
                    // s="standard"
                >
                    {props.children} hello
                </div>
            ),
            link: props => {
                return (
                    <Link to={props.mark.url} className="contentLink">
                        {props.children}
                    </Link>
                );
            },
            strong: props => {
                return (
                    <Text s="standard" w="medium" as="span">
                        {props.children}
                    </Text>
                );
            },
        }
    }

    return (
        <BlockContent
            blocks={props.data}
            serializers={serializers}
        />
    );
};

export default Block;