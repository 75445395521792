import React from 'react';
import styled from "styled-components";
import GlobalStyle from "../../style/GlobalStyle";
import Header from "../head/header";

// fonts
import "../../fonts/fonts.css";
import Footer from "../footer/Footer";
import {Helmet} from "react-helmet";

const Comp = styled.div`
  width: 100%;
  //height: 100%;
  max-width: 768px;
  padding-left: ${({theme}) => theme.paddings.left};
  padding-right: ${({theme}) => theme.paddings.right};
  background: linear-gradient(90deg, ${p => p.theme.colors.complement} 0%, ${p => p.theme.colors.background} 15%);
  color: ${({theme}) => theme.colors.text};
  display: flex;
  flex-direction: column;
`

const Layout = (props) => {
    return (
        <Comp>
            <GlobalStyle/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{props.title}</title>
                {/*<link rel="canonical" href="http://mysite.com/example"/>*/}
            </Helmet>

            <Header sliderText={props.sliderText} locale={props.locale}/>
            {props.children}
            <Footer/>
        </Comp>
    );
};

export default Layout;