import {useEffect, useState} from 'react';

const useDayPercentage = () => {
    const [percentage, setPercentage] = useState(-1);

    useEffect(() => {
        const secondsInADay = 24 * 60 * 60;
        // 'December 17, 1995 22:00:00'
        const now = new Date();
        const hours = now.getHours() * 60 * 60;
        const minutes = now.getMinutes() * 60;
        const seconds = now.getSeconds();
        const totalSeconds = hours + minutes + seconds;
        const percentSeconds = 100 * totalSeconds / secondsInADay;
        setPercentage(percentSeconds);
    }, [setPercentage])
    // console.log(percentage)
    return percentage
};

export default useDayPercentage;