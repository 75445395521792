import styled from "styled-components";

const Text = styled.div.attrs(p => {
    const textStyle = p.theme.text[p.s]
    const weight = p.w ? p.theme.fonts[textStyle.family][p.w] : textStyle.weight
    const transform = p.t ? p.t : p.theme.text[p.s].transform
    const fontStyle = p.st === "italic" ? p.st : textStyle.style ? textStyle.style : null
    return {
        textStyle: textStyle,
        breakPoints: p.theme.breakPoints,

        weight: weight,
        lineHeight: textStyle.lineHeight,
        transform: transform,
        fontStyle: fontStyle,
        family: p.theme.fonts[p.theme.text[p.s].family].family,
        regular: textStyle.size.regular,
        small: textStyle.size.small
    }
})`
  font-family: ${p => p.family}, sans-serif;
  font-weight: ${p => p.weight};
  line-height: ${p => p.lineHeight};
  color: inherit;
  text-transform: ${p => p.transform};
  font-style: ${p => p.st === "italic" ? p.st : p.theme.text[p.s].style ? p.theme.text[p.s].style : null};
  text-align: ${p => p.a ? p.a : null};
  font-size: ${p =>
          `calc(${p.small} + (${parseFloat(p.regular, 10)} - ${parseFloat(p.small, 10)}) * ((100vw - ${p.breakPoints.xs}) / (${parseFloat(p.breakPoints.s, 10)} - ${parseFloat(p.breakPoints.xs, 10)})))`
  };
  transform: translate3d( 0, 0, 0);
  -webkit-font-smoothing: subpixel-antialiased;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;

  // when the wp is wider than the largest breakpoint
  @media (min-width: ${p => p.breakPoints[p.breakPoint ? p.breakPoint : "s"]}) {
    font-size: ${p => p.textStyle.size.regular};
  }
  // when the wp is narrower than the smallest breakpoint
  @media (max-width: ${p => p.breakPoints[p.breakPoint ? p.breakPoint : "xs"]}) {
    font-size: ${p => p.textStyle.size.small};
  }
`;

export default Text;
