import React from "react";
import Video from "../video/Video";
import Spacer from "../layout/Spacer";
import styled from "styled-components";

const Width = styled.div`
  @media (max-width: ${({ theme }) => theme.breakPoints.s}) {
    padding: 0;
  }
`;

const VideoPlayer = (props) => {
	const { data } = props;

	return (
		<Width>
			<Video url={data.node.url} />
			<Spacer s="large" />
		</Width>
	);
};

export default VideoPlayer;