import React from "react";
import styled, {useTheme} from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  height: 100%;
  width: auto;
  //transform: translateX(-50%);
`;

export const DayWheel = () => {
    const theme = useTheme();
    return (
        <Svg viewBox="0 0 167.2 166.5" className="svg_wheel">
            <path fill={theme.colors.paleGrey} d="M83.6,83.3L11.5,41.6C26.8,15.1,53,0,83.6,0V83.3z"/>
            <path fill={theme.colors.orange} d="M83.6,83.3l-72.1,41.6c-15.3-26.5-15.3-56.7,0-83.3L83.6,83.3z"/>
            <path fill={theme.colors.yellow} d="M83.6,83.3v83.3c-30.6,0-56.8-15.1-72.1-41.6L83.6,83.3z"/>
            <path fill={theme.colors.blue} d="M83.6,83.3l72.1,41.6c-15.3,26.5-41.5,41.6-72.1,41.6V83.3z"/>
            <path fill={theme.colors.paleBlue} d="M83.6,83.3l72.1-41.6c15.3,26.5,15.3,56.7,0,83.3L83.6,83.3z"/>
            <path fill={theme.colors.black} d="M83.6,83.3V0c30.6,0,56.8,15.1,72.1,41.6L83.6,83.3z"/>
        </Svg>
    )
};
