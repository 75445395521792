import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  height: 1rem;
  width: auto;
  color: inherit;
`;

export const CloseX = () => (
    <Svg viewBox="0 0 42 42">
        <path d="M40.0922 1.9082L1.9082 40.0922" stroke="currentColor" strokeWidth="12%"/>
        <path d="M40.0922 40.0922L1.9082 1.9082" stroke="currentColor" strokeWidth="12%"/>
    </Svg>
);
