import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: 100%;
  height: auto;
  color: inherit;
  max-width: 200px;
`;

export const LogoHead = () => {
    return (
        <Svg viewBox="0 0 333.9 169.3" className="svg_wheel">
            <path fill="currentColor" d="M308.1,118.3c-13.6,0-22.7,9.7-22.7,25.2c0,15.5,8.1,25.6,22.9,25.6c12.6,0,18.3-7.8,19.9-14.9l-11.3-1.4
		c-1.3,5-4.6,6.6-8.4,6.6c-5.9,0-9.7-3.5-9.9-12.1h30.6c0.2-1.7,0.3-3.4,0.3-5.3C329.5,127.5,320.9,118.3,308.1,118.3z M298.7,139.2
		c0.1-6.8,3.6-11.2,9.1-11.2c6.1,0,8.7,4.2,8.8,11.2H298.7z"/>
            <path fill="currentColor" d="M201.9,118.3c-13.6,0-22.7,9.7-22.7,25.2c0,15.5,8.1,25.6,22.9,25.6c12.6,0,18.3-7.8,19.9-14.9l-11.3-1.4
		c-1.3,5-4.6,6.6-8.4,6.6c-5.9,0-9.7-3.5-9.9-12.1H223c0.2-1.7,0.3-3.4,0.3-5.3C223.3,127.5,214.7,118.3,201.9,118.3z M192.5,139.2
		c0.1-6.8,3.6-11.2,9.1-11.2c6.1,0,8.7,4.2,8.8,11.2H192.5z"/>
            <path fill="currentColor" d="M89,118.3c-13.6,0-22.7,9.7-22.7,25.2c0,15.5,8.1,25.6,22.9,25.6c12.6,0,18.3-7.8,19.9-14.9l-11.3-1.4
		c-1.3,5-4.6,6.6-8.4,6.6c-5.9,0-9.7-3.5-9.9-12.1h30.6c0.2-1.7,0.3-3.4,0.3-5.3C110.4,127.5,101.8,118.3,89,118.3z M79.6,139.2
		c0.1-6.8,3.6-11.2,9.1-11.2c6.1,0,8.7,4.2,8.8,11.2H79.6z"/>
            <polygon fill="currentColor" points="188.2,104.7 213.3,114.5 215.9,109.3 192.9,94.4 	"/>
            <path fill="currentColor" d="M149.6,118.3c-5.2,0-11.1,2.9-13.1,7.6h-0.2c-0.1-2.1-0.2-4.3-0.4-6.3h-12.5c0.3,4.9,0.5,10.5,0.5,15.2v33H137V141
		c0-9,4.6-12,8.9-12c2.7,0,4.7,1.2,5.7,2.7c1.2,1.8,1.7,4,1.7,7.5v28.4h13.1V137c0-5.4-1.2-9.5-3.4-12.7
		C160.4,120.6,156.4,118.3,149.6,118.3z"/>
            <path fill="currentColor" d="M27.5,139.4h12.4v0.9c0,9.9-3.6,17.1-12.1,17.1c-7.6,0-12.7-6.8-12.7-24.4c0-17.8,4.7-24.5,13-24.5
		c6.4,0,9.5,4.1,10.2,11.7l14-2c-0.6-9.4-7-21.7-24-21.7C9.9,96.4,0,110.1,0,132.9c0,24.2,11.4,36.4,26.9,36.4
		c7,0,12.9-3.7,15.4-10.2h0.2l1.6,8.6h8.6V129H27.5V139.4z"/>
            <path fill="currentColor" d="M257.9,140.6c-1.4,4.5-2.2,7.7-3.1,12h-0.2c-1-4.2-1.8-7.5-3.1-12l-6.7-21.1h-14l17.7,48.2h11.9l17.7-48.2h-13.5
		L257.9,140.6z"/>
            <path fill="currentColor" d="M251.4,56.8l3.4,12.8h15.5L248.4,0h-13.9l-21.9,69.6h14.8l3.5-12.8H251.4z M237.7,31.4c1.2-4.3,2.5-9.4,3.5-14.6h0.1
		c1.1,5.2,2.5,10.3,3.5,14.5l3.5,14.2h-14.4L237.7,31.4z"/>
            <rect fill="currentColor" x="5.1" y="31.6" width="63.9" height="5.7"/>
            <path fill="currentColor" d="M316.8,67.9c12.7-4.6,17.1-17.6,17.1-34.2c0-13.8-4.6-26.9-16.9-31.7c-3.2-1.3-7.1-2-11.6-2h-22.9v69.6h22.9
		C309.8,69.6,313.6,69,316.8,67.9z M297.1,11.6h6.9c12.5,0,15.1,11.6,15.1,23.2c0,13.3-3.5,23.2-13.7,23.2h-8.2V11.6z"/>
            <polygon fill="currentColor" points="203.2,57.7 174.5,57.7 174.5,39.4 197.9,39.4 197.9,28.1 174.5,28.1 174.5,12 203.2,12 203.2,0 159.8,0
		159.8,69.6 203.2,69.6 	"/>
            <polygon fill="currentColor"
                     points="106,40 125.5,40 125.5,69.6 140.2,69.6 140.2,0 125.5,0 125.5,28 106,28 106,0 91.2,0 91.2,69.6 106,69.6 	"/>

        </Svg>
    )
};
