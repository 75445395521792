import styled from "styled-components";

const Conditional = styled.div`
  ${(props) =>
          props.s
                  ? "display: contents;"
                  : props.xxs
                  ? "display: none;"
                  : null};

  @media (max-width: calc(${({theme}) => theme.breakPoints.xxs})) {
    ${(props) =>
            props.xxs
                    ? "display: contents;"
                    : props.s
                    ? "display: none;"
                    : null};
  }
`;

export default Conditional;
