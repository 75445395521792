import React from 'react';
import styled from "styled-components";
import Spacer from "./Spacer";

const Sep = styled.div`
  height: 2px;
  //border-bottom: 2px dashed ${({theme}) => theme.colors.text};
  background-image: linear-gradient(to right, ${({theme}) => theme.colors.text} 50%, rgba(255,255,255,0) 0%);
  background-position: center;
  background-size: 7px 2px;
  background-repeat: repeat-x;
`

const Full = styled(Sep)`
  width: calc(100% + ${({theme}) => theme.paddings.left} + ${({theme}) => theme.paddings.right});
  position: relative;
  left: -${({theme}) => theme.paddings.left};
`

const Partial = styled(Sep)`
  width: 40%;
  align-self: center;
`

const Separator = (props) => {
    return (
        <>
            {props.full ? <Full/> : <Partial/>}
            <Spacer s="large"/>
        </>
    );
};

export default Separator;