import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import DayGradient from "./DayGradient";
import Link from "../localization/Link";

const Comp = styled.div`
  display: flex;
  flex-direction: column;
`

const Titles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Languages = styled.div`
  display: flex;
`

const LangText = styled(Text)`
`

const LanguageLink = styled(Link)`
    //color: ${({theme}) => theme.colors.text};
  color: ${p => !p.selected ? p.theme.colors.paleBlue : p.theme.colors.text} !important;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const Header = (props) => {
    const {locale} = props;

    return (
        <Comp>
            <Spacer s="medium"/>
            <Titles>
                <Text s="logo">
                    Circa Diem
                </Text>
                <Languages>
                    <LangText s="languages">
                        <LanguageLink to="/" selected={locale === "en"}>
                            EN
                        </LanguageLink>
                    </LangText>
                    <Text s="languages">
                        /
                    </Text>
                    <LangText s="languagesKo" style={{marginTop: "0.2rem"}}>
                        <LanguageLink to="/fr" selected={locale === "fr"}>
                            FR
                        </LanguageLink>
                    </LangText>
                </Languages>
            </Titles>

            <Spacer s="small"/>
            <DayGradient sliderText={props.sliderText}/>

            <Spacer s="small"/>
            <Spacer s="small"/>
        </Comp>
    );
};

export default Header;
