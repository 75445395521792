import React from 'react';
import styled from "styled-components";
import Block from "../editorial/Block";
import {CloseX} from "../icons/CloseX";

const Comp = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(calc(-50% - ${({theme}) => theme.paddings.left})) translateY(-50%);
  width: calc(100% - (${({theme}) => theme.paddings.left} + ${({theme}) => theme.paddings.right}));
  max-width: calc(${({theme}) => theme.breakPoints.s} - (${({theme}) => theme.paddings.left} + ${({theme}) => theme.paddings.right}));
    //min-width: ${({theme}) => theme.breakPoints.xs};
  height: ${p => p.alert ? "auto" : "80%"};
  background-color: ${({theme}) => theme.colors.white};
  color: ${({theme}) => theme.colors.black};
  padding: ${({theme}) => theme.paddings.left};
  margin: 0 ${({theme}) => theme.paddings.left};
  border: 2px solid ${({theme}) => theme.colors.black};
  border-radius: 1rem;
  display: ${p => p.open ? "block" : "none"};
  z-index: 15;

  a {
    //background-color: ${({theme}) => theme.colors.black};
    color: ${({theme}) => theme.colors.black};
    text-decoration: underline;
    //padding: 0 0.2rem;
    // border-bottom: 2px solid ${({theme}) => theme.colors.black};

    :hover {
      background-color: ${({theme}) => theme.colors.black};
      color: ${({theme}) => theme.colors.white};
    }
  }
`

const Content = styled.div`
  overflow-y: auto;
  height: 100%;
`

const CloseButton = styled.div`
  border: 2px solid ${({theme}) => theme.colors.text};
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1rem;
  top: -1rem;
  background-color: ${({theme}) => theme.colors.white};
    // color: ${({theme}) => theme.colors.black};
  cursor: pointer;

  :hover {
    background-color: ${({theme}) => theme.colors.black};
    color: ${({theme}) => theme.colors.white};
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.colors.background};
  opacity: 0.7;
  display: ${p => p.open ? "block" : "none"};
  z-index: 12;
`

const PopIn = (props) => {
    return (
        <>
            <Comp open={props.open} alert={props.alert}>
                <CloseButton onClick={() => props.setOpen(false)}><CloseX/></CloseButton>
                <Content>
                    <Block data={props.text} alert={props.alert}/>
                </Content>
            </Comp>
            <Overlay open={props.open} onClick={() => props.setOpen(false)}/>
        </>
    );
};

export default PopIn;