import React from "react";
import styled from "styled-components";
import Icon from "./Icon";

const Svg = styled(Icon)`
  width: 100%;
  height: auto;
  color: inherit;
  max-width: 200px;
`;

export const LogoEPFL = () => {
    return (
        <Svg viewBox="0 0 347.1 101" className="svg_wheel">
            <path fill="currentColor" d="M163.3,8.7c-5.4-5-12-7.3-19.2-8.3c-1.2-0.2-2.4-0.3-3.6-0.4H91.8c0,0.3,0,0.5,0,0.8c0,33.1,0,66.3,0,99.4
	c0,0.3,0,0.5,0,0.8h21.8c0-0.1,0-0.3,0-0.4c0-13.4,0-26.7,0-40.1c0-0.2,0-0.4,0-0.7h0.9c7.4,0,14.8,0,22.2,0c2.2,0,4.4-0.1,6.6-0.3
	c4.5-0.5,8.9-1.6,13-3.6c5.6-2.8,10.1-6.9,12.7-12.7c3-6.8,3.5-13.8,1.9-21C169.8,16.9,167.3,12.4,163.3,8.7z M143.3,39.6
	c-2.8,1-5.8,1.4-8.8,1.5c-6.8,0.1-20.7,0.1-20.9,0.1V18.7c0.2,0,13.4-0.1,19.8,0c3.5,0.1,7.1,0.4,10.4,1.7c3.6,1.5,6,3.9,6.5,7.9
	C151,33.8,148.5,37.7,143.3,39.6z"/>
            <path fill="currentColor" d="M346.4,82.3c-16.4,0-32.8,0-49.1,0c-0.2,0-0.5,0-0.8,0v-0.9c0-26.9,0-53.7,0-80.6c0-0.3,0-0.5,0-0.8h-21.8
	c0,0.3,0,0.5,0,0.8c0,33.1,0,66.3,0,99.4c0,0.3,0,0.5,0,0.8h72.4V82.3C346.8,82.3,346.6,82.3,346.4,82.3z"/>
            <path fill="currentColor" d="M0,41.2c0.3,0,21.4-0.1,21.8-0.1V18.6h51.3V0H0V41.2z"/>
            <path fill="currentColor" d="M207.6,18.7h1c16.5,0,50.3,0,50.3,0c0-5.7,0-12.3,0-17.9c0-0.2,0-0.4,0-0.7h-73.1c0,0.3,0.1,0.6,0.1,0.9
	c0,13.1-0.1,40.2-0.1,40.2s14.3,0,21,0c0.3,0,0.6,0,0.9,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-7.2,0-14.5,0-21.7
	C207.5,19.1,207.6,18.9,207.6,18.7z"/>
            <path fill="currentColor" d="M21.8,59.9c-0.3,0-21.5,0-21.8-0.1V101h73.1V82.3H21.8V59.9z"/>
            <path fill="currentColor" d="M207.6,59.8c-0.3,0-0.6,0.1-0.9,0.1c-6.7,0-20.9,0.1-20.9,0.1s0,27,0,40.1c0,0.3,0,0.6-0.1,0.9h21.8c0-0.3,0-0.6,0-0.9
	c0-13.2,0-26.3,0-39.5C207.5,60.4,207.6,60.1,207.6,59.8C207.6,59.8,207.6,59.8,207.6,59.8C207.6,59.8,207.6,59.8,207.6,59.8z"/>
            <path fill="currentColor" d="M207.6,41.2c0,0.3,0,0.5,0,0.8c0,4.9,0,9.8,0,14.7c0,1,0,2.1,0,3.1c0,0,0,0,0,0c0,0,0,0,0,0c15.5,0,46.7,0,46.8,0V41.2h-2.8
	C236.9,41.2,222.2,41.2,207.6,41.2C207.6,41.2,207.6,41.2,207.6,41.2C207.6,41.2,207.6,41.2,207.6,41.2z"/>
            <rect fill="currentColor" x="21.9" y="41.2" width="46.8" height="18.5"/>

        </Svg>
    )
};
