import React, {createRef, useState} from 'react';
import styled, {keyframes} from "styled-components";
import useDayPercentage from "../../hooks/useDayPercentage";
import PopIn from "../layout/PopIn";
import {DayWheel} from "../icons/DayWheel";

const Container = styled.div`
  padding: 1rem;
`

const Comp = styled.div`
  width: 100%;
  background: linear-gradient(90deg,
  ${({theme}) => theme.colors.black} 0%,
  ${({theme}) => theme.colors.black} 25%,
  ${({theme}) => theme.colors.blue} 25%,
  ${({theme}) => theme.colors.blue} 33%,
  ${({theme}) => theme.colors.paleBlue} 33%,
  ${({theme}) => theme.colors.paleBlue} 41%,
  ${({theme}) => theme.colors.paleGrey} 41%,
  ${({theme}) => theme.colors.paleGrey} 75%,
  ${({theme}) => theme.colors.yellow} 75%,
  ${({theme}) => theme.colors.yellow} 83%,
  ${({theme}) => theme.colors.orange} 83%,
  ${({theme}) => theme.colors.orange} 91%,
  ${({theme}) => theme.colors.black} 91%,
  ${({theme}) => theme.colors.black} 100%);
  height: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`
const appearAnim = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-1080deg);
  }
`
const idleAnim = keyframes`
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
`

const CircleContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  left: ${p => p.position}%;
  opacity: ${p => p.position > -1 ? "1" : "0"};
  transition: all 1s, opacity 0.3s;
`

const Circle = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  top: 0;
  left: -50%;
  cursor: pointer;
  animation-name: ${appearAnim}, ${idleAnim};
  animation-duration: 2s, 10s;
  animation-delay: 0s, 2s;
  animation-timing-function: ease-in-out, linear;
  animation-iteration-count: 1, infinite;
`
// const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;

const DayGradient = (props) => {
    const dayPercentage = useDayPercentage();
    const [open, setOpen] = useState(false)

    return (
        <Container
            onMouseDown={() => setOpen(true)}
            onTouchStart={() => setOpen(true)}
        >
            <Comp
            >
                <CircleContainer
                    position={dayPercentage}
                    //onMouseDown={() => setOpen(true)}
                    //onTouchStart={() => setOpen(true)}
                >
                    <Circle position={dayPercentage}>
                        <DayWheel/>
                    </Circle>
                </CircleContainer>

            </Comp>
            <PopIn alert text={props.sliderText} open={open} setOpen={setOpen}/>
        </Container>
    );
};

export default DayGradient;