import React from 'react';
import styled from "styled-components";
import {LogoEPFL} from "../icons/LogoEPFL";
import {LogoHead} from "../icons/LogoHead";
import Spacer from "../layout/Spacer";
import Link from "../localization/Link";

const Comp = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const Footer = () => {
    return (
        <>
            <Comp>
                <Link to="https://www.hesge.ch/head/en">
                    <LogoHead/>
                </Link>
                <Spacer s="medium"/>
                <Link to="https://www.epfl.ch/en/">
                    <LogoEPFL/>
                </Link>
            </Comp>
            <Spacer s="large"/>
        </>
    );
};

export default Footer;