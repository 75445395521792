import React from 'react';
import styled from "styled-components";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import {useTimeContext} from "../../contexts/TimeContext";

const Image = styled.img`
  width: 100%;
`

const EditorialImage = (props) => {
        const {data} = props
        const time = useTimeContext();
        const image = time.quarter ? data.images && data.images.find(i => i.time === time.quarter.time) ?
            data.images.find(i => i.time === time.quarter.time).image.asset.url : null : null

        return (
            <>
                {data.title ?
                    <>
                        <Text s="contentTitle">
                            {data.title}
                        </Text>
                        <Spacer s="medium"/>
                    </> : null}

                {/*{props.data.image ? <Image src={props.data.image.asset.url} alt={props.data.caption}/> : null}*/}
                {image ? <Image src={image} alt={data.title}/> : null}
                <Spacer s="large"/>
            </>
        );
    }
;

export default EditorialImage;