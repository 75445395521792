import React from 'react';
import styled from "styled-components";
import Block from "./Block";
import Text from "../layout/Text";
import Spacer from "../layout/Spacer";
import Conditional from "../layout/Conditional";
import {useTimeContext} from "../../contexts/TimeContext";

const Comp = styled.div`
  display: flex;
  flex-direction: ${p => p.right ? "row" : "row-reverse"};
  align-items: flex-start;

  // when the wp is smaller than the breakpoint
  @media (max-width: calc(${({theme}) => theme.breakPoints.xxs})) {
    flex-direction: column;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Image = styled.img`
  width: 100%;
`
const ImageContainer = styled.div`
  //padding-top: 1rem;
  flex: 0.5;
  display: flex;
  align-items: center;
`

const ImageText = (props) => {
    const {data} = props
    const time = useTimeContext();
    const image = time.quarter ? data.images && data.images.find(i => i.time === time.quarter.time) ?
        data.images.find(i => i.time === time.quarter.time).image.asset.url : null : null

    return (
        <>
            <Comp right={data.position === "right"}>
                <TextContainer>
                    <Text s="contentTitle" as="h3">
                        {data.title}
                    </Text>

                    <Conditional xxs>
                        <Spacer s="small"/>
                        <ImageContainer>
                            {image ?
                                <Image src={image} alt={data.title}/> : null}
                        </ImageContainer>
                    </Conditional>
                    <Spacer s="small"/>

                    <Block data={data.Text} imageText/>
                </TextContainer>

                <Conditional s>
                    <Spacer s="medium"/>
                    <ImageContainer>
                        {image ?
                            <Image src={image} alt={data.title}/> : null}
                    </ImageContainer>
                </Conditional>

                {/*<Conditional xxs>*/}
                {/*    <Spacer s="medium"/>*/}
                {/*</Conditional>*/}
            </Comp>
            <Spacer s="large"/>
        </>
    );
};

export default ImageText;