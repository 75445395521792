import React, { useRef} from "react";
import YouTube from "react-youtube";
import * as styles from "./style.module.css";
import getVideoId from "get-video-id";

const Video = (props) => {
	const videoPlayer = useRef();

	const opts = {
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			start: 0,
			autoplay: 0,
			rel: 0,
			modestbranding: 1,
		},
	};

	const video = getVideoId(props.url);

	return (
		<YouTube
			videoId={video.id}
			opts={opts}
			containerClassName={styles.wrapper}
			ref={videoPlayer}
		/>
	);
};

export default Video;
