import {createGlobalStyle} from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
    transition: color 0.5s, background-color 0.5s, background 0.5s;

    ::selection {
      background-color: ${({theme}) => theme.colors.complement};
      color: ${({theme}) => theme.colors.background};
    }
  }

  html {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    border: 0;
    overflow-x: hidden;
      //background-color: ${({theme}) => theme.colors.background};
    font-family: ${({theme}) => theme.fonts[theme.text.standard.family].family}, sans-serif;
      //font-weight: ${({theme}) => theme.text.standard.weight};
    line-height: ${({theme}) => theme.text.standard.lineHeight};
    color: ${({theme}) => theme.colors[theme.text.standard.color]};
    //scroll-behavior: smooth;
      // background-color: ${({theme}) => theme.colors.black};
    background: linear-gradient(90deg, ${p => p.theme.colors.complement} 0%, ${p => p.theme.colors.complement} 50%, ${p => p.theme.colors.background} 50%, ${p => p.theme.colors.background} 100%);
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }

  #___gatsby {
    width: 100%;
    height: 100%;
  }

  #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h1, h3 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  
  p {
    display: block;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
  } 

  .contentLink {
    //background-color: ${({theme}) => theme.colors.text};
    color: ${({theme}) => theme.colors.text};
    text-decoration: underline;
    //padding: 0 0.2rem;
    //border-bottom: 2px solid ${({theme}) => theme.colors.text};

    :hover {
      background-color: ${({theme}) => theme.colors.text};
      color: ${({theme}) => theme.colors.background};
    }
  }

  // input {
  //   font-family: ${({theme}) => theme.fonts[theme.text.buttonSmall.family].family}, sans-serif;
  //     //font-size: ${({theme}) => theme.text.buttonSmall.size};
  //   font-weight: ${({theme}) => theme.text.buttonSmall.weight};
  //   line-height: ${({theme}) => theme.text.buttonSmall.lineHeight};
  //   color: ${({theme}) => theme.colors.accent};
  // }

  a {
    text-decoration: none;
    color: ${({theme}) => theme.colors.text};
  }

  *:focus {
    outline: 0 !important;
    -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${({theme}) => theme.colors.background};
    border-radius: 1rem;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${({theme}) => theme.colors.complement};
    border-radius: 1rem;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: ${({theme}) => theme.colors.text}
  }
`;

export default GlobalStyle;